import request from '@/utils/request'

/*
* 根据寺庙id获取设备列表
*
* */
export const getDeviceListByTempleId = (obj)=>{
  return request.get('/device/getDeviceListByTempleId',{
    params:{
      templeId: obj.templeId,
      pageIndex: obj.pageIndex,
      pageSize: obj.pageSize
    }
  }).catch(r=>{
    console.log(r)
  })
}

/*
* 查询设备是否在线
* */
export const getDeviceSign = (IMEIID)=>{
  return request.get('/device/getDeviceSignel',{
    params:{
      IMEIID
    }
  }).catch(r=>{
    console.log(r)
  })
}

export const getDeviceStatusMap = (IMEIID,deviceType)=>{
  return request.get('/device/getDeviceStatusMap',{
    params:{
      IMEIID,
      deviceType
    }
  }).catch(r=>{
    console.log(r)
  })
}

