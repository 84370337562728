<template>
  <div class="device-status-content">
    <van-action-sheet :value="show" @click-overlay="closeFn">
      <div v-if="!isRedAll">
        <div :class="{
        // deviceType === 3 ? 'three-box' : 'four-box'
        'three-box': deviceType === '3',
        'four-box': deviceType === '4',
        'one-box': deviceType === '1'
      }">
          <van-grid :column-num="columnNum" :border="false">
            <van-grid-item class="item-box" v-for="(value, key, index) in finalDeviceStatusMap" :key="index">
              <div class="tagbox">
                <van-sidebar v-show="deviceType === '3' && index % 4 === 0">
                  <van-sidebar-item>
                    <template #title>
                      <span>{{ tagArr[index / 4] }}</span>
                    </template>
                  </van-sidebar-item>
                </van-sidebar>
              </div>
              <div
                class="content"
                :class="{
                'lighted-choose-box': isSelected(value, key) && value === 0,
                'lighted-die-choose': !isSelected(value, key) && value === 0,
                'lighting-choose-box': isSelected(value, key) && value === 1,
                'lighting-die-choose': !isSelected(value, key) && value === 1,
                'disabled-box': value === 1 && !isAllLight,
              }"
                @click="toggleBoxStatus(key,value)"
              >
                <div class="svg">
                  <svg-icon icon-class="meal" v-if="value === 0"/>
                  <svg-icon icon-class="meal-h" v-else/>
                </div>
                <div class="box">
                  <div class="number">{{ +key + 1 }}号心灯</div>
                  <div class="islight" v-if="value === 0">未点亮</div>
                  <div class="islight" v-else>已点亮</div>
                </div>
              </div>
            </van-grid-item>
          </van-grid>
        </div>
        <div class="col-flex" v-show="selectCommit">
          <div class="confirm" @click="commitFn">确定</div>
          <div class="cancel" @click="closeFn">取消</div>
        </div>

      </div>
      <div class="redAll-box" v-else>
        <van-sidebar class="sidebar-box">
          <van-sidebar-item title="满堂红"/>
        </van-sidebar>
        <div
          class="all-box"
            :class="{ 'choose-all': isRedAll }"
          @click="setIsChoose"
        >
          <div class="all-left">
            <div>
              <img :src="require('@/assets/lianhuadeng.png')" alt="" class="all-img"/>
            </div>
            <div>全部点亮</div>
          </div>
          <div>可全部点亮</div>
        </div>
        <div class="col-flex" v-show="selectCommit">
          <div class="confirm" @click="commitFn">确定</div>
          <div class="cancel" @click="closeFn">取消</div>
        </div>
      </div>
    </van-action-sheet>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'DeviceStatus',
  components: {},
  props: {
    selectCommit: Boolean,
    show: Boolean,
    deviceStatusMap: Object
  },
  data () {
    return {
      tagArr: ['健康快乐', '福慧双增', '好运连连'],
      selectPorts: [],
      deviceType: '',
    }
  },
  computed: {
    ...mapState(['isRedAll']),
    ...mapState('device',['device']),
    finalDeviceStatusMap () {
      if (this.deviceStatusMap && Object.keys(this.deviceStatusMap).length > 0) {
        return this.deviceStatusMap // 如果已经传入 deviceStatusMap，直接使用它
      } else {
        // 从 Vuex 中获取 deviceStatusMap 或者设置一个默认值
        // 这里是一个示例，默认设为空对象
        return this.$store.state.device.device.deviceStatusMap || {}
      }
    },
    columnNum () {
      if (Object.keys(this.finalDeviceStatusMap).length === 12) {
        this.deviceType = '3'
        return 4
      }
      if (Object.keys(this.finalDeviceStatusMap).length === 24) {
        this.deviceType = '4'
        return 6
      }
      if (Object.keys(this.finalDeviceStatusMap).length === 1) {
        this.deviceType = '1'
        return 1
      }
    },
    isAllLight () {
      return Object.values(this.finalDeviceStatusMap).every(value => value === 1)
    },
  },
  methods: {
    commitFn () {
      const performLogic = () => {
        if (this.isRedAll) {
          // 如果是满堂红
          let ports = '';
          this.$store.commit('setRedAll', true);
          if (this.deviceType === '3') {
            ports = Array.from({ length: 12 }, (_, index) => index.toString());
          } else if (this.deviceType === '4') {
            ports = Array.from({ length: 24 }, (_, index) => index.toString());
          }
          this.$store.commit('setLightingArr', ports);
        } else {
          this.$store.commit('setLightingArr', this.selectPorts);
        }
        this.$emit('closeAction', false);
      };
      if (this.isAllLight && this.selectPorts.length > 0) {
        this.$dialog.confirm({
          title: '确认选择',
          message: '当前已选端口已点亮，继续点亮可预约订单',
        }).then(() => {
          // 确认选择
          performLogic();
        }).catch(() => {
            return
          })
      }else {
        performLogic();
      }
      // if(this.isRedAll){
      //   //如果是满堂红
      //   let ports = ''
      //   this.$store.commit('setRedAll',true)
      //   if (this.deviceType === '3') {
      //     ports = Array.from({ length: 12 }, (_, index) => index.toString())
      //   } else if (this.deviceType === '4') {
      //     ports = Array.from({ length: 24 }, (_, index) => index.toString())
      //   }
      //   this.$store.commit('setLightingArr',ports)
      // }else{
      //   this.$store.commit('setLightingArr',this.selectPorts)
      // }
      // this.$emit('closeAction', false)

    },
    closeFn () {
      if (!this.isRedAll && this.deviceType !== '1') {
        this.selectPorts = []
        this.$store.commit('setLightingArr', [])
      }
      this.$emit('closeAction', false)
    },
    isSelected (value, key) {
      return this.selectPorts.includes(key)
    },
    toggleBoxStatus (key, value) {
      if (!this.selectCommit || this.deviceType === '1') {
        return
      }
      if (value === 1 && !this.isAllLight) {
        // this.$toast.fail({
        //   message: '当前心灯已点亮',
        //   position: 'top',
        //   duration: 1000,
        // })
        this.$notify({
          message: '当前心灯已点亮！',
          color: '#000000',
          background: '#FFD700',
          duration: 1000
        })
        return
      }
      const index = this.selectPorts.indexOf(key)
      if (index !== -1) {
        this.selectPorts.splice(index, 1)
      } else {
        this.selectPorts.push(key)
      }
    },
    setIsChoose () {},
    checkAndRemoveSelectedPorts() {
      if(!this.isAllLight){
        // 获取用户当前选择的端口
        const selectedPorts = this.selectPorts.slice();
        // 遍历用户选择的端口
        for (const port of selectedPorts) {
          // 检查端口状态是否为1
          if (this.finalDeviceStatusMap[port] === 1) {
            // 如果是1，则从选择的端口中移除
            const index = this.selectPorts.indexOf(port);
            if (index !== -1) {
              this.selectPorts.splice(index, 1);
            }
          }
        }
      }
    },
    handleDeviceTypeChange(value){
      if (this.selectCommit && value === '1') {
        this.selectPorts.push('0');
        this.$store.commit('setLightingArr', this.selectPorts);
      }
    },
  },
  watch: {
    deviceType: {
      immediate: true, // 立即执行
      handler(value) {
        this.handleDeviceTypeChange(value);
      },
    },
  },
  //  生命周期 -创建完成（可以访问当前this 实例）
  created () {
    // if (Object.keys(this.finalDeviceStatusMap).length === 12) {
    //   return '3'
    // }
    // if (Object.keys(this.finalDeviceStatusMap).length === 24) {
    //   return '4'
    // }
    // if (Object.keys(this.finalDeviceStatusMap).length === 1) {
    //   return '1'
    // }
    // 初始化 deviceType
    if (Object.keys(this.finalDeviceStatusMap).length === 12) {
      this.$set(this, 'deviceType', '3');
    } else if (Object.keys(this.finalDeviceStatusMap).length === 24) {
      this.$set(this, 'deviceType', '4');
    } else if (Object.keys(this.finalDeviceStatusMap).length === 1) {
      this.$set(this, 'deviceType', '1');
    }
    this.$store.state.isRedAll = false
    if(this.deviceType !== '1'){
      this.$store.state.lightingArr = []
    }
  },
//  生命周期 - 挂载完成（可以访问Dom元素）
  mounted () {
    // this.$nextTick(() => {
    //   if (this.comboType.length > 0) {
    //     this.selectComboFn(this.comboType[0]);
    //     this.confirmLight();
    //   }
    // });
  },
//  生命周期 - 创建之前
  beforeCreate () {
  },
//  生命周期 - 挂载之前
  beforeMount () {
  },
//  生命周期 - 更新之前
  beforeUpdate () {
  },
//  生命周期 - 更新之后
  updated () {
    this.checkAndRemoveSelectedPorts();
  },
//  生命周期 - 销毁之前
  beforeDestroy () {
  },
//  生命周期 - 销毁完成
  destroyed () {
  },
// 如果页面有keep-alive 缓存功能，这个函数会触发
  activated () {
  }
}
</script>

<style scoped lang="less">
/* 样式部分保持不变 */
</style>


<style scoped lang="less">
.device-status-content {
  :deep .van-grid-item__content--center {
    justify-content: initial !important;
  }

  :deep .van-grid-item__content {
    padding: 0 0;
  }

  :deep .van-action-sheet {
    max-height: 100vh;
  }

  //满堂红
  .redAll-box {
    padding: 0 15px;

    .sidebar-box {
      height: 48px;
      line-height: 48px;
      text-align: left;
      font-size: 15px;
      font-family: PingFangSC-Medium, PingFang SC, sans-serif;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.85);
      overflow: hidden;
    }

    .all-box {
      padding: 15px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: rgba(0, 0, 0, 0.04);
      border-radius: 6px;
      font-size: 12px;

      .all-left {
        display: flex;
        align-items: center;
        justify-content: center;

        .all-img {
          width: 30px;
          height: 25px;
          line-height: 30px;
          margin-right: 10px;
        }
      }
    }
    .choose-all {
      border: 2px solid #b34341;
    }
  }


  //12PIN
  .three-box {
    margin: 10px 15px 15px 15px;

    .item-box {
      margin: 0 0;
      height: 156.6px;
      /* 调整透明度 */

      .tagbox {
        margin: 10px 0;
        height: 30px;

        :deep .van-sidebar-item {
          padding: 10px 15px;
          font-size: 16px;

          .van-sidebar-item__text {
            width: 80px;
          }
        }
      }


      .content {
        width: 80px;
        padding-top: 15px;
        height: 106px;
        display: flex;
        flex-direction: column;
        text-align: center;
        justify-content: center;
        color: rgba(0, 0, 0, .45);
        //background: #fbf1ee;
        //border: 1px solid #fff;
        //background: #f2f2f2;
        .svg {
          display: flex;
          justify-content: center;
          text-align: center;

          .svg-icon {
            width: 40px;
            height: 32px;
            font-size: 32px;
          }
        }

        .box {
          font-size: 16px;

          .number {
          }

          .islight {
          }
        }
      }
    }
  }

  .four-box {
    padding: 30px 5px 10px;

    .item-box {
      width: 50px;
      height: 95px;
      padding: 0 2px;

      .content {
        display: flex;
        flex-direction: column;
        text-align: center;
        justify-content: center;
        color: rgba(0, 0, 0, .45);
        height: 90%;
        width: 100%;

        .svg {
          .svg-icon {
            width: 35px;
            height: 31px;
          }
        }

        .box {
          .number {
            font-size: 12px;
          }

          .islight {
            font-size: 12px;
          }
        }
      }
    }
  }

  .one-box {
    padding: 30px 10px 10px;

    .item-box {
      width: 80px;
      height: 135px;

      .content {
        display: flex;
        flex-direction: column;
        text-align: center;
        justify-content: center;
        color: rgba(0, 0, 0, .45);
        height: 90%;
        width: 80px;

        .svg {
          .svg-icon {
            width: 40px;
            height: 32px;
          }
        }
      }
    }
  }

  .col-flex {
    height: 94px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 10px 0;

    .confirm {
      width: 325px;
      height: 42px;
      line-height: 42px;
      background: #A92B2A;
      border-radius: 21px;
      text-align: center;
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC, sans-serif;
      font-weight: 500;
      color: #ffffff;
      letter-spacing: 1px;
      margin-bottom: 5px;
    }

    .cancel {
      width: 325px;
      height: 42px;
      border-radius: 21px;
      border: 1px solid #e0e6e9;
      text-align: center;
      line-height: 42px;
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC, sans-serif;
      font-weight: 500;
      color: #999999;
      letter-spacing: 1px;
    }
  }

  .lighting-choose-box {
    /* 灯亮 + 选中 */
    /* color: red; */

    //background: #fbf1ee;
    background: #f5e5e0;
    border-radius: 6px;
    border: 2px solid #b34341;
    box-sizing: border-box;
  }

  .lighting-die-choose {
    /* 灯亮 + 未选中 */

    //background: #fbf1ee;
    background: #f5e5e0;
    border-radius: 6px;
    border: 1px solid #fff;
    box-sizing: border-box;
  }

  .lighted-choose-box {
    /* 未亮 + 选中 */

    background: rgba(0, 0, 0, 0.04);
    border-radius: 6px;
    border: 2px solid #b34341;
    box-sizing: border-box;
  }

  .lighted-die-choose {
    /* 灯未亮 + 未选中 */
    border-radius: 6px;
    background: rgba(0, 0, 0, 0.04);
    border: 1px solid #fff;
    box-sizing: border-box;
  }

  .disabled-box {
    //pointer-events: none; /* 禁用点击事件 */
    //opacity: 0.5; /* 可以使用不透明度来表示不可选状态 */
  }


}
</style>
