var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box"},[_c('van-divider',{style:({
      margin: '30px 0',
      color: '#000',
      fontWeight: 'bold',
      borderColor: '#666',
      padding: '0 0',
    })},[_vm._v(" "+_vm._s(_vm.title)+" ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }