<template>
  <div class="box">
    <van-divider
      :style="{
        margin: '30px 0',
        color: '#000',
        fontWeight: 'bold',
        borderColor: '#666',
        padding: '0 0',
      }"
    >
      {{ title }}
    </van-divider>
  </div>
</template>


<script >
export default {
  props: ["title"],
  data() {
    return {};
  },
  components: {},
  methods: {},
};
</script>

<style scoped>
.box{
  padding: 0 15px;
  box-sizing: border-box;
}

</style>
