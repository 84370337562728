<template>
  <div class="banner-container">
    <van-swipe class="banner-swipe" :autoplay="5000" indicator-color="white">
      <template v-for="(item) in templeImgArr" :key="index">
        <van-swipe-item >
          <van-image
            class="bless-img-img svg-icon-img"
            fit="fill"
            :src="`${item}`"
          >
<!--             src="https://cdn.zjbbkj.com/temple/88f219928c5aa6513da0d82b52a209b5.jpg"-->
<!--               :src="`${item}?imageView2/1/w/375/h/177/interlace/1`"     -->
          </van-image>
        </van-swipe-item>
      </template>
    </van-swipe>

  </div>
</template>

<script>
// 这里可以导入其他文件 例如：组件、工具js、第三方插件js,json文件、图片文件等
// eg: import 《组件名称》 from '《文件路径》'
import { mapGetters } from 'vuex'

export default {
  // import 引入的组件需要注入到对象中才能使用
  name: 'BannerSwipe',
  components: {},
  props: {
  },
  // 这里用于存放数据
  data() {
    return {}
  },
//  计算属性 类似于data概念
  computed: {
    ...mapGetters('temple',['templeImgArr'])
  },
//  监控data 中数据变化
  watch: {},
//  方法集合
  methods: {},
//  生命周期 -创建完成（可以访问当前this 实例）
  created() {
  },
//  生命周期 - 挂载完成（可以访问Dom元素）
  mounted() {
  },
//  生命周期 - 创建之前
  beforeCreate() {
  },
//  生命周期 - 挂载之前
  beforeMount() {
  },
//  生命周期 - 更新之前
  beforeUpdate() {
  },
//  生命周期 - 更新之后
  updated() {
  },
//  生命周期 - 销毁之前
  beforeDestroy() {
  },
//  生命周期 - 销毁完成
  destroyed() {
  },
// 如果页面有keep-alive 缓存功能，这个函数会触发
  activated() {
  }

}
</script>

<style lang="less" scoped>
.banner-container {
  //position: absolute;
  //top: 0;
  //left: 0;
  //right: 0;
  width: 375px;
  height: 174px;
  .banner-swipe{
    .bless-img-img{
      margin: 1px;
      width: calc(100vw - 2px);
      height: 172px;
    }
  }
}
</style>
